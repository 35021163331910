import gameTarget from '../assets/images/game-target.png'
import gameHeaderTarget from '../assets/images/game-header-target.png'
import gameRace from '../assets/images/game-race.png'
import gameHeaderRace from '../assets/images/game-header-race.png'
import gameTournament from '../assets/images/game-tournament.png'
import gameHeaderTournament from '../assets/images/game-header-tournament.png'

export enum GameType {
  Race = 'Race',
  Tournament = 'Tournament',
  Incentive = 'Incentive',
}

export interface GameCard {
  gameType: any
}

export enum RewardType {
  Chests = 'chests',
  Loot = 'loot_boxes',
  Tokens = 'tokens',
  Jackpot = 'token_jackpot',
}

export interface Reward {
  type:
    | RewardType.Chests
    | RewardType.Tokens
    | RewardType.Loot
    | RewardType.Jackpot
  value?: any
}

interface GameDisplay {
  title: string
  bgColor: string
  completedBgColor: string
  prizeBgColor: string
  rulesBgColor: string
  headerBgColor: string
  headerCompletedBgColor: string
  image: string
  headerImage: string
}

export const gameDisplay = {
  [GameType.Race]: {
    title: 'Race',
    bgColor: 'spyro.500',
    completedBgColor:
      'linear-gradient(332.62deg, #18366F -56.94%, #4B4695 33.21%, #8959C3 143.91%)',
    prizeBgColor: 'rgba(35, 34, 34, 0.2)',
    rulesBgColor: 'spyro.500',
    image: gameRace,
    headerImage: gameHeaderRace,
    headerBgColor: '#8959c3',
    headerCompletedBgColor:
      'linear-gradient(269.42deg, #18366F 0.28%, #4B4695 50.95%, #8959C3 113.16%)',
  },
  [GameType.Tournament]: {
    title: 'Tournament',
    bgColor:
      'linear-gradient(70.79deg, #FF665C 10.52%, #8959C3 57.31%, #05255B 102.27%)',
    completedBgColor:
      'linear-gradient(135.13deg, #FE665C 0.77%, #8A5AC3 47.74%, #142B67 88.77%)',
    prizeBgColor: 'linear-gradient(253.76deg, #9E3F5D -0.78%, #BB3B42 98.26%)',
    rulesBgColor: 'bandicoot.500',
    image: gameTournament,
    headerImage: gameHeaderTournament,
    headerBgColor:
      'linear-gradient(85.41deg, #FF665C 22.37%, #8959C3 75.87%, #05255B 100.84%)',
    headerCompletedBgColor:
      'linear-gradient(85.41deg, #FF665C 22.37%, #8959C3 75.87%, #05255B 100.84%)',
  },
  [GameType.Incentive]: {
    title: 'Incentive',
    bgColor: 'venasaur.500',
    completedBgColor:
      'linear-gradient(142.32deg, #1BC4BB 10.51%, #0D9A93 96.23%)',
    prizeBgColor: 'rgba(5, 37, 91, 0.2)',
    rulesBgColor: 'venasaur.500',
    image: gameTarget,
    headerImage: gameHeaderTarget,
    headerBgColor:
      'linear-gradient(85.41deg, #1BC4BB 22.37%, var(--chakra-colors-venasaur-500) 45.87%)',
    headerCompletedBgColor:
      'linear-gradient(142.32deg, #1BC4BB 10.51%, #0D9A93 96.23%)',
  },
} as Record<GameType, GameDisplay>
