import { Icon } from '@chakra-ui/icon'

const RaffleTicket = props => (
  <Icon viewBox="0 0 522 386" fill="none" {...props}>
    <defs>
      <linearGradient
        id="a"
        x1="465.624"
        x2="64.928"
        y1="330.39"
        y2="49.905"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF85A2" />
        <stop offset="1" stopColor="#AD1338" />
      </linearGradient>
      <linearGradient
        id="b"
        x1="-15.205"
        x2="553.78"
        y1="114.009"
        y2="274.274"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF85A2" />
        <stop offset="1" stopColor="#590217" />
      </linearGradient>
      <linearGradient
        id="c"
        x1="16.85"
        x2="513.71"
        y1="130.037"
        y2="258.247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF85A2" />
        <stop offset="1" stopColor="#FFD8E1" />
      </linearGradient>
      <radialGradient
        id="d"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="rotate(74.076 3.56 275.965) scale(183.735 183.76)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#830F2B" />
        <stop offset="1" stopColor="#830F2B" stopOpacity="0" />
      </radialGradient>
      <filter
        id="e"
        width="484.43"
        height="489.239"
        x="18.566"
        y="-49.1"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_3323_31881"
          stdDeviation="78.948"
        />
      </filter>
    </defs>
    <path
      fill="url(#a)"
      stroke="url(#b)"
      strokeLinejoin="round"
      strokeWidth="13.158"
      d="M484.398 173.394c-22.14 5.933-44.897-7.206-50.83-29.345-5.932-22.14 7.207-44.897 29.347-50.829 5.046-1.352 8.683-6.606 7.171-12.246l-15.82-59.042c-2.798-10.443-13.533-16.639-23.975-13.84L21.933 117.508c-10.442 2.798-16.64 13.53-13.842 23.972l14.874 55.508c1.813 6.765 8.793 9.782 14.643 8.215 22.14-5.933 44.898 7.206 50.83 29.345 5.932 22.14-7.206 44.897-29.346 50.829-5.851 1.568-10.388 7.67-8.575 14.436l17.217 64.254c2.798 10.443 13.533 16.639 23.975 13.841l408.358-109.418c10.442-2.798 16.64-13.53 13.842-23.972l-17.177-64.105c-1.511-5.639-7.288-8.372-12.334-7.02Z"
    />
    <path
      stroke="url(#c)"
      strokeLinejoin="round"
      strokeWidth="3.289"
      d="M425.625 146.177c7.108 26.527 34.374 42.269 60.901 35.161 1.035-.277 2.025.318 2.262 1.204l17.177 64.106c1.623 6.054-1.971 12.277-8.026 13.9L89.58 369.965c-6.055 1.623-12.28-1.971-13.902-8.025L58.46 297.686c-.487-1.818.712-3.816 2.759-4.365 26.527-7.107 42.269-34.373 35.161-60.9-7.108-26.527-34.374-42.269-60.9-35.161-2.047.548-4.086-.582-4.573-2.4l-14.873-55.508c-1.623-6.054 1.97-12.277 8.026-13.9L432.42 16.035c6.056-1.623 12.281 1.97 13.903 8.025l15.821 59.043c.237.886-.322 1.896-1.357 2.174-26.527 7.107-42.269 34.373-35.161 60.9Z"
    />
    <path
      fill="#FFADC1"
      stroke="url(#d)"
      strokeWidth="6.579"
      d="m281.909 144.522-20.354-31.489c-8.585-13.283-29.156-7.771-29.949 8.025l-1.882 37.447a9.71 9.71 0 0 1-7.206 8.897l-36.238 9.626c-15.287 4.06-16.401 25.329-1.622 30.964l35.03 13.356a9.71 9.71 0 0 1 6.236 9.603l-2.045 37.434c-.863 15.792 19.019 23.425 28.946 11.113l23.537-29.192a9.712 9.712 0 0 1 11.058-2.963l34.98 13.513c14.753 5.699 28.155-10.852 19.511-24.097l-20.488-31.396a9.71 9.71 0 0 1 .599-11.435l23.659-29.082c9.981-12.269-1.618-30.132-16.887-26.004l-36.196 9.783a9.71 9.71 0 0 1-10.689-4.103Z"
    />
    <g filter="url(#e)">
      <path
        fill="#FFD8E1"
        d="M233.892 121.16c.633-12.606 17.05-17.004 23.901-6.404l20.354 31.489a13 13 0 0 0 14.31 5.493l36.196-9.783c12.186-3.294 21.443 10.961 13.477 20.753l-23.658 29.082a12.998 12.998 0 0 0-.803 15.308l20.488 31.396c6.898 10.57-3.797 23.779-15.571 19.231l-34.979-13.513a13.002 13.002 0 0 0-14.805 3.967l-23.536 29.192c-7.923 9.826-23.79 3.734-23.101-8.869l2.045-37.434a13 13 0 0 0-8.349-12.856l-35.03-13.356c-11.795-4.497-10.906-21.471 1.294-24.712l36.239-9.625a13 13 0 0 0 9.646-11.912l1.882-37.447Z"
      />
    </g>
  </Icon>
)

RaffleTicket.displayName = 'RaffleTicketIcon'

export default RaffleTicket
